import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, CircularProgress, Grid, Tooltip, IconButton, FormHelperText } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { sentenceCase } from 'sentence-case';
import { colorForStatus, orderStatuses } from './helpers/statuses';
import { AttachMoney as PaidIcon } from '@material-ui/icons';

// styles
import useStyles from './styles';

// components
import { Button, Typography, Chip } from '../../components/Wrappers/Wrappers';
import Widget from '../../components/Widget/Widget';

// context
import { useOrderDispatch, useOrderState, actions as orderActions } from '../../context/OrderContext';
import { usePracticeDispatch, usePracticeState, actions as practiceActions } from '../../context/PracticeContext';

// helpers
import { getMethodLabel, getPracticeName } from '../../common/helpers';
import { excludedProperties } from './helpers/form';

const ViewOrder = () => {
    const classes = useStyles();

    const orderDispatch = useOrderDispatch();
    const orderState = useOrderState();

    const practiceDispatch = usePracticeDispatch();
    const practiceState = usePracticeState();

    const history = useHistory();
    const location = useLocation();

    const entries = Object.entries(orderState?.currentOrder || {});

    useEffect(() => {
        if (!practiceState.practices) practiceActions.doFetch()(practiceDispatch);
    }, []); // eslint-disable-line

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const practiceId = queryString.get('practiceId');
        const orderId = queryString.get('orderId');
        if (!practiceId || !orderId) {
            toast.error('Unable to find practice id or order id');
            setTimeout(() => backToList(), 2000);
        } else if (!orderState.currentOrder || orderState.currentOrder.orderId !== orderId) {
            orderActions.doFind(practiceId, orderId)(orderDispatch);
        }
    }, [location.search]); //eslint-disable-line

    useEffect(() => {
        if (!orderState.findError) return;
        setTimeout(() => backToList(), 2000);
    }, [orderState?.findError]); //eslint-disable-line

    const handleOpenLink = (url) => (_) => window.open(url, '_blank');

    const handleCopyToken = (token) => (_) => navigator.clipboard.writeText(token);

    const handleDownloadFile = (filekey) => (_) => orderActions.doDownloadFile(filekey);

    const handleTreatmentInvoiced = () => {
        const { orderId, practiceId } = orderState.currentOrder;
        const data = { treatmentInvoiced: true, practiceId };
        orderActions.doUpdate(orderId, data)(orderDispatch);
    };

    const handleCancelOrder = () => {
        const { orderId, practiceId } = orderState.currentOrder;
        orderActions.doCancel(orderId, practiceId)(orderDispatch, history);
    };

    const handleCompleteOrder = (isPaid) => () => {
        const { orderId, practiceId } = orderState.currentOrder;
        orderActions.doComplete(orderId, practiceId, isPaid)(orderDispatch, history);
    };

    const backToList = () => history.goBack();

    const handleClickEdit = () => {
        orderActions.doInitUpdate()(orderDispatch);
        const { orderId, practiceId } = orderState.currentOrder;
        history.push(`edit?practiceId=${practiceId}&orderId=${orderId}`);
    };

    const handleClickCreateRefinement = () => {
        const { orderId, practiceId } = orderState.currentOrder;
        history.push(`refine?practiceId=${practiceId}&orderId=${orderId}`);
    };

    const handleClickViewRefinement = () => {
        const { practiceId, refinementOrderId } = orderState.currentOrder;
        history.push(`view?practiceId=${practiceId}&orderId=${refinementOrderId}`);
    };

    const renderEntry =
        (order) =>
        ([key, value]) => {
            if (excludedProperties.includes(key)) return null;
            if (!value || !value.length) return null;
            if (key.match('orderId|previousOrderId|refinementOrderId')) return null;
            if (key === 'links') return value.map(renderCaseLink);
            if (key.match('patientPhotos|treatmentPlanFile')) return renderDownloadAction(key, value);

            const orderStatus = order?.orderStatus;

            // order cancellation, completion and refinement
            const canCancelOrder = orderStatus === orderStatuses.CASE_NEEDS_APPROVAL;
            const canCompleteOrder = orderStatus === orderStatuses.CASE_IN_PRODUCTION;
            const canCreateRefinement = orderStatus === orderStatuses.CASE_COMPLETE && !order?.refinementOrderId;
            const orderHasRefinement = orderStatus === orderStatuses.CASE_COMPLETE && order?.refinementOrderId;

            return (
                <Grid item container key={key}>
                    <Grid item xs={6}>
                        <Typography variant="body1" weight="bold">
                            {key.match('IPR')
                                ? sentenceCase(key).replace('ipr', 'IPR')
                                : key.match('practiceId')
                                ? 'Practice'
                                : sentenceCase(key)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {key === 'orderStatus' ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '12px',
                                }}
                            >
                                <Chip label={value} color={colorForStatus(value)} />
                                {orderState.currentOrder?.isPaid && (
                                    <IconButton>
                                        <PaidIcon color="primary" />
                                    </IconButton>
                                )}
                                {orderState.currentOrder?.orderStatus === orderStatuses.CASE_NEEDS_APPROVAL &&
                                    orderState.currentOrder?.treatmentInvoiced && (
                                        <Tooltip
                                            title={
                                                <Typography fontSize={30}>
                                                    Case pending for over 2 months. Treatment plan only has been
                                                    invoiced and will be deducted from total course cost when case is
                                                    approved.
                                                </Typography>
                                            }
                                        >
                                            <IconButton>
                                                <WarningIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                {canCancelOrder && (
                                    <>
                                        <Button onClick={handleCancelOrder} variant="contained" color="secondary">
                                            Cancel Order
                                        </Button>
                                        <Typography style={{ marginTop: 15 }}>
                                            Note: If you cancel the order a design fee of $149 will be invoice as TX has
                                            been provided.
                                        </Typography>
                                    </>
                                )}

                                {canCompleteOrder && (
                                    <Button onClick={handleCompleteOrder(false)} variant="contained" color="secondary">
                                        Complete Order
                                    </Button>
                                )}

                                {canCreateRefinement && (
                                    <Button onClick={handleClickCreateRefinement} variant="contained" color="secondary">
                                        Create Refinement
                                    </Button>
                                )}

                                {orderHasRefinement && (
                                    <Button onClick={handleClickViewRefinement} variant="outlined" color="secondary">
                                        View Refinement
                                    </Button>
                                )}
                            </div>
                        ) : key === 'practiceId' ? (
                            <Typography className={classes.text}>
                                {getPracticeName(practiceState.practices?.items || [], value)}
                            </Typography>
                        ) : key === 'method' ? (
                            <Typography className={classes.text}>{getMethodLabel(value)}</Typography>
                        ) : key === 'instructions' || key === 'comments' ? (
                            value.split('\n').map((item, idx) => (
                                <span key={idx}>
                                    <Typography className={classes.text}>{item}</Typography>
                                </span>
                            ))
                        ) : key === 'dentalMonitoring' && value === 'Yes' ? (
                            <strong className={classes.dentalMonitoring}>{value}</strong>
                        ) : (
                            <Typography className={classes.text}>{value}</Typography>
                        )}
                    </Grid>
                </Grid>
            );
        };

    const renderDownloadAction = (key, value) => (
        <Grid item container alignItems="center" key={key}>
            <Grid item xs={6}>
                <Typography variant="body1" weight="bold">
                    {sentenceCase(key)}
                </Typography>
            </Grid>
            <Grid item container xs={6} alignItems="center">
                <Button
                    className={classes.openLinkButton}
                    color="primary"
                    variant="contained"
                    onClick={handleDownloadFile(value)}
                >
                    Download
                </Button>
            </Grid>
        </Grid>
    );

    const renderCaseLink = ({ url, type, token }) => {
        const isApprovalLink = type === 'approval';
        return (
            <Grid item container alignItems="center" key={`link-${type}`}>
                <Grid item xs={6}>
                    {isApprovalLink ? (
                        <>
                            <Typography variant="body1" weight="bold">
                                Dentist link
                            </Typography>
                            <FormHelperText>(for case approval)</FormHelperText>
                        </>
                    ) : (
                        <>
                            <Typography variant="body1" weight="bold">
                                Patient link
                            </Typography>
                            <FormHelperText>(forward to patient - 3D viewer only)</FormHelperText>
                        </>
                    )}
                </Grid>
                <Grid item container xs={6} alignItems="center">
                    {isApprovalLink && (
                        <Tooltip
                            placement="top"
                            color="secondary"
                            disableFocusListener
                            disableTouchListener
                            title="Copy to access the case details"
                            enterDelay={500}
                        >
                            <Button
                                className={classes.copyTokenButton}
                                color="primary"
                                variant="contained"
                                onClick={handleCopyToken(token)}
                            >
                                Click to copy token
                            </Button>
                        </Tooltip>
                    )}
                    <Button
                        className={classes.openLinkButton}
                        color="secondary"
                        variant="contained"
                        onClick={handleOpenLink(url)}
                    >
                        Open 3D viewer
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const Footer = () => {
        const order = orderState?.currentOrder;
        const orderStatus = order?.orderStatus;

        // invoice logic
        const canMarkAsPaid = orderStatus === orderStatuses.CASE_COMPLETE && !order?.isPaid;
        const canMarkAsInvoiced = orderStatus === orderStatuses.CASE_NEEDS_APPROVAL && !order?.treatmentInvoiced;

        return (
            <Box display="flex" justifyContent="space-between" style={{ marginTop: 50 }}>
                <Button onClick={backToList} variant="outlined" color="secondary">
                    Back
                </Button>
                <Button onClick={handleClickEdit} variant="contained" color="secondary">
                    Edit
                </Button>

                {canMarkAsPaid && (
                    <Button onClick={handleCompleteOrder(true)} variant="contained" color="secondary">
                        Mark as Paid
                    </Button>
                )}
                {canMarkAsInvoiced && (
                    <Button onClick={handleTreatmentInvoiced} variant="contained" color="secondary">
                        Tx Invoiced
                    </Button>
                )}
            </Box>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Widget>
                    <Grid item justifyContent="center" container>
                        <Box display="flex" flexDirection="column" width={720}>
                            <Typography variant="h5" weight="medium" style={{ marginBottom: 30 }}>
                                Order details
                            </Typography>

                            <Grid container direction="column" spacing={3}>
                                {orderState?.findLoading ? (
                                    <CircularProgress color="secondary" className={classes.progressCentered} />
                                ) : (
                                    entries.map(renderEntry(orderState?.currentOrder))
                                )}
                            </Grid>

                            <Footer />
                        </Box>
                    </Grid>
                </Widget>
            </Grid>
        </Grid>
    );
};

export default ViewOrder;
