export const practiceAttributes = [
    {
        label: 'Account Information',
        type: 'section',
    },
    {
        id: 'name',
        label: 'Name',
        required: true,
    },
    {
        id: 'email',
        label: 'Email',
        type: 'email',
        required: true,
    },
    {
        id: 'territory',
        label: 'Territory',
        items: ['QLD-1', 'QLD-2', 'NSW-1', 'NSW-2', 'NZ'],
        required: true,
    },
    {
        label: 'Legal Name & Address',
        type: 'section',
    },
    {
        id: 'tradingName',
        label: 'Trading Name',
    },
    {
        id: 'abn',
        label: 'ABN',
        type: 'number',
    },
    {
        id: 'address',
        label: 'Street Address',
        required: true,
    },
    {
        id: 'suburb',
        label: 'Suburb',
        required: true,
    },
    {
        id: 'postcode',
        label: 'Postcode',
        type: 'number',
        required: true,
    },
    {
        id: 'state',
        label: 'State/Country',
        items: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NZ'],
        required: true,
    },
    {
        label: 'Contact Details',
        type: 'section',
    },
    {
        id: 'practiceManager',
        label: 'Practice Manager Name',
    },
    {
        id: 'prescriber1',
        label: 'Prescriber Name 1',
    },
    {
        id: 'prescriber2',
        label: 'Prescriber Name 2',
    },
    {
        id: 'prescriber3',
        label: 'Prescriber Name 3',
    },
    {
        id: 'prescriber4',
        label: 'Prescriber Name 4',
    },
    {
        id: 'phoneNumber',
        label: 'Phone Number',
        type: 'tel',
    },
    {
        label: 'Treatment Notes',
        type: 'section',
    },
    {
        id: 'allowFullArchTreatment',
        label: 'Allow Full Arch Treatment',
        items: ['Yes', 'No'],
        required: true,
    },
    {
        id: 'scanOrImpression',
        label: 'Scan Or Impression',
        items: ['Scan', 'Impression'],
    },
    {
        id: 'scannerBrand',
        label: 'Scanner Brand',
    },
    {
        id: 'clearAlignerBrand',
        label: 'Clear Aligner Brand',
    },
    {
        id: 'pricingNotes',
        label: 'Pricing / Notes',
        type: 'textArea',
    },
];
