import moment from 'moment';
import { paramCase } from 'change-case';
import { scanMethod } from '../pages/order/helpers/form';

export const extractExtensionFrom = (filename) => {
    if (!filename) return null;
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(filename)[1];
};

export const extractNameFrom = (filename) => {
    if (!filename) return null;
    const regex = /(.+?)(\.[^.]*$|$)/;
    return regex.exec(filename)[1];
};

/**
 * Creates a filename for the treatment archive with the following format:
 *
 * - For a new case: <parent_folder>/<patientName>_<prescriberName>_<trimline>_<timestamp>.zip
 * - For a refinement case: <parent_folder>/<patientName>_refinement_<parent_case_number>_<trimline>_<timestamp>.zip
 *
 * where parent_folder can be:
 * - treatment_plan: when the treatment plan is provided
 * - patient_photos: when traditional impressions are used
 * - not defined: when the treatment is uploaded to full contour (3D Scan and treatment plan not provided)
 */
export const getUniqueFilename = (order, fileExtension, uploadToFullContour) => {
    const patient = paramCase(order.patientName);
    const prescriber = paramCase(order.prescriberName);
    const marker = moment().format('YYMMDDHHmm');
    const trimLine = order.trimLine.match(/scalloped/i) ? 'scalloped' : 'straight';
    let filename = '';
    if (order.refinement === 'Yes') {
        filename = `${patient}_refinement_${order.previousCaseNumber}_${trimLine}_${marker}.${fileExtension}`;
    } else {
        filename = `${patient}_${prescriber}_${trimLine}_${marker}.${fileExtension}`;
    }

    if (uploadToFullContour) return filename;

    const folder = order.provideTreatmentPlan === 'Yes' ? 'treatment_plan' : 'patient_photos';
    return `${folder}/${filename}`;
};

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
};

export const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

export const getPracticeName = (practices, id) => {
    const practice = practices.find((p) => p.practiceId === id);
    return practice?.name;
};

export const getPracticeTradingName = (practices, id) => {
    const practice = practices.find((p) => p.practiceId === id);
    return practice?.tradingName;
};

export const getPracticeForId = (practices, id) => practices.find((p) => p.practiceId === id);

export const getMethodLabel = (method) => (method === scanMethod ? '3D Scan' : 'Traditional Impression');

export const deepClone = (obj) => {
    if (obj === null) return null;

    let clone = Object.assign({}, obj);
    Object.keys(clone).forEach((key) => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]));
    if (Array.isArray(obj)) {
        clone.length = obj.length;
        return Array.from(clone);
    }
    return clone;
};
